export default class Card {
    id: string

    constructor(id: string) {
        this.id = id
    }

    getPath() : string {
        return "cards/" + this.id + ".svg"
    }

    getSuit() : string {
        return this.id.charAt(this.id.length - 1).toString()
    }

    getValue() : string {
        return this.id.substring(0, this.id.length - 1)
    }

    getSuitNumber() : number {
        switch(this.getSuit()) {
            case "D":
                return 0
            case "H":
                return 2
            case "S":
                return 1
            case "C":
                return 3
        }  
        return 0
    }

    getValueNumber() : number {

        switch (this.getValue()) {
            case "7":
                return 0
            case "8":
                return 1
            case "9":
                return 2
            
            case "J":
                return 4
            case "Q":
                return 5
            case "K":
                return 6

            case "10":
                return 10
            case "A":
                return 11

        }
        return 0
    }
}