export enum GameStage {
    REIZEN = 0,
    STOCK = 1,
    SPIEL = 2,
}

export enum GameMode {
    KARO = "KARO",
    HERZ = "HERZ",
    PICK = "PICK",
    KREUZ = "KREUZ",
    GRAND = "GRAND",
    NULL = "NULL",
}