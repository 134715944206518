import { handleError, initCustomFormatter } from "vue";
import CanvasHelper from "./CanvasHelper";
import { GameMode, GameStage } from "./GameEnums";
import Card from "./model/Card";
import Table from "./model/Table";
import axios from "axios"
import Player from "./model/Player";

export default class GameManager {

    // private canvas: CanvasHelper
    private stage: GameStage = GameStage.STOCK

    private playerId: string = ""
    private gameId: string = ""
    private originUrl: string = ""
    private playerName: string = ""

    private turn : boolean = false
    private playing: boolean = false
    private playerCount: number = 1
    private players: Player[] = []
    private mode: GameMode = GameMode.GRAND


    onStateChange: () => void = () => {}

    private table: Table = {
        hand: [new Card("9H"), new Card("7S")],
        stock: [new Card("AS"), new Card("AH")],
        stich: [new Card("9S"), new Card("JS"), new Card("KS")],
    }

    constructor() {

        // canvas.onHandSelect = card => {
        //     console.log("remove card with id " + card.id)
        //     if (this.stage === GameStage.STOCK && this.playing) {
        //         this.table.hand = this.table.hand.filter((c) => card.id !== c.id)
        //         this.table.stock.push(card)
        //     }
        //     if (this.stage === GameStage.SPIEL) {
        //         this.table.hand = this.table.hand.filter((c) => card.id !== c.id)
        //         if (this.turn) this.playCard(card)
        //         else window.alert("Du bist nicht dran!")
        //     }
        //     this.updateCanvas()
        // }

        // canvas.onStockSelect = card => {
            // this.table.stock = this.table.stock.filter(c => c.id !== card.id)
            // this.table.hand.push(card)
            // this.updateCanvas()
        // }

        // canvas.onStichClock = () => {
        //     if (this.table.stich.length != 3) return
        //     this.claimStich()
        // }
    }

    onHandCardSelect(card: Card) {
            console.log("remove card with id " + card.id)
            if (this.stage === GameStage.STOCK && this.playing) {
                this.table.hand = this.table.hand.filter((c) => card.id !== c.id)
                this.table.stock.push(card)
            }
            if (this.stage === GameStage.SPIEL) {
                if (this.turn) {
                    this.playCard(card)
                    this.table.hand = this.table.hand.filter((c) => card.id !== c.id)
                }
                else window.alert("Du bist nicht dran!")
            }
            this.updateCanvas()
    }

    onStockCardSelect(card: Card) {
        this.table.stock = this.table.stock.filter(c => c.id !== card.id)
        this.table.hand.push(card)
        this.updateCanvas()
    }

    setGameId(id: string) {
        this.gameId = id
    }

    setPlayerName(name: string) {
        this.playerName = name
    }

    setOrigin(url: string) {
        this.originUrl = url
    }

    getStage() : GameStage {
        return this.stage
    }

    getTurn() : boolean {
        return this.turn
    }

    getPlaying() : boolean {
        return this.playing
    }

    getMode() : GameMode {
        return this.mode
    }

    getTable() : Table {
        return this.table
    }

    getPlayers() : Player[] {
        return this.players
    }

    getPlayersCount() : number {
        return this.playerCount
    }

    setPlayerToken(token: string) {
        this.playerId = token

        const socket = new WebSocket(this.originUrl + "/game/socket")
        socket.onopen = () => {
            socket.send(JSON.stringify({
                gameId: this.gameId,
                playerToken: this.playerId,
            }))
        }
        socket.onmessage = event => {
            console.log("Got an update...")
            console.log(event)
            this.dataToTable(JSON.parse(event.data))
        }
    }

    // fetch() {
    //     axios.get("/game/get/" + this.gameId, { params: { playerToken: this.playerId } })
    //         .then(data => {
    //            this.dataToTable(data.data)
    //         })
    // }

    private dataToTable(data: any) {
        this.table.hand = data.hand.map((e: string) => new Card(e))
        this.table.stock = data.stock.map((e: string) => new Card(e)) 
        this.table.stich = data.stich.map((e : string) => new Card(e))
        this.turn = data.isTurn
        this.stage = data.stage
        this.playerCount = data.numberPlayers
        this.playing = data.playing
        this.players = data.players
        this.mode = data.mode
        if (data.points != null) window.alert("Du hast " + data.points + " erziehlt.")
        this.updateCanvas()
        this.onStateChange()
    }

    claimStich() {
        axios.post("/game/claim", {
            playerToken: this.playerId,
            gameId: this.gameId,
        })
    }
    
    reset() {
        axios.post("/game/reset", {
            playerToken: this.playerId,
            gameId: this.gameId,
        })
    }

    private playCard(card: Card) {
        axios.post("/game/playcard", {
            card: card.id,
            playerToken: this.playerId,
            gameId: this.gameId,
        })
    }

    updateCanvas() {
        // this.canvas.drawTable(this.table, this.stage === GameStage.STOCK && this.playing)
    }

    hasLegalHand() : boolean {
        return this.table.hand.length == 10
    }

    doneReizen() {
        console.log("Done reizen")
        axios.post("/game/bit", {
            playerToken: this.playerId,
            gameId: this.gameId,
        }).then(() => {
            // this.fetch()
        })
    }

    doneChoosingGame(mode: GameMode) {
        axios.post("/game/play", {
            playerToken: this.playerId,
            gameId: this.gameId,
            hand: this.table.hand.map(card => card.id),
            mode: mode,
        })
    }
}