import { createApp, reactive } from 'vue'
import App from './App.vue'
import { GameMode, GameStage } from './GameEnums'
import GameManager from './GameManager'
import Player from './model/Player'
import Table from './model/Table'

export const store = reactive({ 
  stage: GameStage.REIZEN,
  playerToken: "", 
  turn: false,
  playing: false,
  playersCount: 1,
  table: {} as Table,
  game: new GameManager(),
  lobby: true,
  players: {} as Player[],
  mode: GameMode.GRAND,
})

createApp(App).mount('#app')
